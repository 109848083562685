import React from 'react'
import styled from 'styled-components'
import { Box, Text } from '../../components/Shared'

const Wrapper = styled(Box)`
  > * {
  }
  iframe {
    max-width: 650px;
  }
  section {
    max-width: 950px;
  }

  p {
    margin-bottom: 2rem;
  }
`

const VideoTestimonial = (props) => {
  const { copy, video, videoTitle } = props
  return (
    <Wrapper
      as="article"
      display="flex"
      flexDirection="column"
      alignItems="center"
      bg="gallery"
      p={2}
      py={5}
    >
      <iframe
        title={videoTitle || 'InkHouse Video Embed'}
        width="100%"
        height="400"
        src={video
          .replace('/watch?v=', '/embed/')
          .replace('&feature=youtu.be', '?feature=oembed')}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
      <Text
        dangerouslySetInnerHTML={{ __html: copy }}
        textAlign="center"
        maxWidth="700px"
        line
        p={5}
      />
    </Wrapper>
  )
}

export default VideoTestimonial
