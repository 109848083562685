import React from 'react'

import { Box, Flex, Heading, Span, Text } from '../../components/Shared'
import styled from 'styled-components'
import { CtaLink } from '../../components/LinkCta'

const Underline = styled(Span)`
  border-bottom: 5px solid #f6b227;
`

const FilteredSvg = styled.img`
  max-height: 11rem;
  padding-bottom: 3rem;
  width: 100%;
  height: 500px;
`

const Section = (props) => {
  const {
    textColor,
    headingColor,
    section: { button, caption, copy },
    orderNumber,
  } = props

  const illustration = props.section.illustration
    ? props.section.illustration
    : null

  let buttonLink, buttonText
  if (button) {
    buttonLink = button[0].link
    buttonText = button[0].text
  }

  return (
    <Box
      as="article"
      display="flex"
      flexDirection="column"
      position="relative"
      my={[4, 0, 0]}
      px={3}
      textAlign="center"
    >
      <Box display="flex" flexDirection="column" position="relative">
        {illustration ? (
          <FilteredSvg src={illustration.localFile.publicURL} alt="" />
        ) : (
          <Span
            margin="0 auto"
            color="burntSienna"
            mb={4}
            fontSize="7rem"
            fontWeight={200}
          >
            {orderNumber + 1}
          </Span>
        )}
        <Underline />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        minHeight={['null', 'null', '300px']}
        py={3}
      >
        <Span fontSize={[3, 4, 5]} fontWeight={700} color={headingColor}>
          {caption}
        </Span>
        <Text color={textColor} dangerouslySetInnerHTML={{ __html: copy }} />
        {button && (
          <CtaLink variant="yellow" display="block" url={buttonLink}>
            {buttonText}
          </CtaLink>
        )}
      </Box>
    </Box>
  )
}

const ComponentIllustrated = ({
  backgroundColor,
  section,
  heading,
  headingColor,
  textColor,
  linkText,
  linkUrl,
}) => {
  return (
    <Box
      as="section"
      bg={backgroundColor || 'white'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py={6}
    >
      <Heading
        as="h2"
        color={headingColor}
        textAlign="center"
        fontSize={6}
        px={[5, 4, 5]}
        mb={5}
        maxWidth="800px"
      >
        {heading}
      </Heading>
      <Flex
        as="section"
        position="relative"
        flexDirection={['column', 'column', 'row']}
        alignItems={['center', 'center', 'flex-start']}
        justifyContent="center"
        px={5}
        my={5}
        maxWidth="1600px"
      >
        {section.map((s, i) => (
          <Section
            key={i}
            orderNumber={i}
            section={s}
            textColor={textColor}
            headingColor={headingColor}
          />
        ))}
      </Flex>
      {linkUrl && (
        <CtaLink url={linkUrl} variant="yellow">
          {linkText}
        </CtaLink>
      )}
    </Box>
  )
}

export default ComponentIllustrated
