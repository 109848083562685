import React from 'react'
import FluidImage from '../../components/FluidImage'
import { Box, Heading } from '../../components/Shared'

const Brands = ({ logos, heading, image, backgroundColor }) => {
  return (
    <Box
      as="section"
      position="relative"
      bg={backgroundColor}
      display={image && 'flex'}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={5}
    >
      {heading && (
        <Heading as="h2" mb={5} textAlign="center">
          {heading}
        </Heading>
      )}
      {logos ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexWrap={['wrap', 'wrap', 'nowrap']}
          maxWidth="1000px"
          margin="0 auto"
        >
          {logos.map((logo, index) => {
            return (
              <FluidImage
                key={index}
                image={logo.image}
                imgStyle={{ objectFit: 'contain' }}
                width="100%"
                m="0.5rem"
                maxWidth="300px"
              />
            )
          })}
        </Box>
      ) : (
        <Box p={5}>
          <FluidImage image={image} minWidth="300px" />
        </Box>
      )}
    </Box>
  )
}

export default Brands
