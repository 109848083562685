import React from 'react'
import styled from 'styled-components'

import FluidImage from '../../components/FluidImage'
import { Box } from '../../components/Shared'
import signature from '../../images/ImEman.svg'
import underline from '../../images/underlineWhite.svg'

const FramedTestimonials = styled(Box)`
  margin-top: -5rem;
  margin-bottom: -6rem;
  z-index: 1;
`

const Copy = styled(Box)`
  p {
    padding-bottom: 2rem;
  }

  em {
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 3rem 0;
    @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
      background: url(${underline});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 0px 100px;
    }
  }
`

const AboutMe = (props) => {
  const { copy, image, framedTestimonials } = props

  return (
    <Box as="section" position="relative">
      <Box
        as="img"
        maxWidth={['10rem', '20rem', '30rem']}
        src={signature}
        alt="Hey! I'm Eman"
        style={{ display: 'block', margin: 'auto', padding: '2.5rem 0' }}
      />
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        justifyContent="center"
        alignItems="center"
        bg="mandysPink"
        p={5}
        pb={6}
        width="100vw"
      >
        <FluidImage image={image} mb={[4, 4, 6]} maxWidth="700px" />
        <Copy
          display="flex"
          flexDirection="column"
          dangerouslySetInnerHTML={{ __html: copy }}
          px={[2, 4, 5]}
          pb={2}
          maxWidth={[null, null, '700px']}
          lineHeight={3}
        />
      </Box>
      <FramedTestimonials
        display="grid"
        position="relative"
        gridTemplateColumns={['1fr', '1fr', '1fr 2fr']}
        gridAutoRows="auto"
        gridGap="2rem"
        margin="0 auto"
        maxWidth="1200px"
        p={4}
        // mx={['2rem', '5rem', '12rem']}
      >
        {framedTestimonials.map((ft, index) => (
          <FluidImage
            key={index}
            imgStyle={{ objectFit: 'contain' }}
            // minWidth={['10rem', '15rem', '20rem']}
            maxHeight="300px"
            // maxWidth="500px"

            image={ft.testimonialImage}
            alt={ft.alt}
            id={`frame-${index}`}
          />
        ))}
      </FramedTestimonials>
    </Box>
  )
}

export default AboutMe
