import React from 'react'

import { Box, Flex, Heading, Span, Text } from '../../components/Shared'
import styled from 'styled-components'
import CtaLink from '../../components/LinkCta'

const Copy = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    max-width: 450px;
    font-size: 1rem;
    line-height: 1.89;
  }

  blockquote {
    color: ${({ theme }) => theme.colors.toreaBay};
    padding-bottom: 3rem;
    p {
      font-weight: 600;
      font-size: 1.7rem;
      line-height: 1.2;
      max-width: 550px;
    }
  }
`

const Section = (props) => {
  const {
    numberOrder,
    isLast,
    section: { copy, linkLabel, linkUrl },
  } = props

  const illustration = props.section.illustration
    ? props.section.illustration
    : null

  const isEven = numberOrder % 2 === 0

  return (
    <Box
      as="article"
      display="flex"
      alignItems="center"
      flexDirection="column"
      p={6}
      px={[0, 4, 6]}
      bg={isEven ? 'mandysPink' : 'gallery'}
      width="100vw"
    >
      <Box
        display="flex"
        width="100%"
        maxWidth="1200px"
        alignItems="center"
        flexDirection={['column', 'column', isEven ? 'row' : 'row-reverse']}
      >
        {illustration && (
          <img
            src={illustration.localFile.publicURL}
            style={{ width: '50%', maxHeight: '350px' }}
          />
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="relative"
          flex={1}
          width="50%"
          pt={[4, 4, 0]}
        >
          <Copy
            dangerouslySetInnerHTML={{ __html: copy }}
            pb={[4, 4, 5]}
            style={{ flexGrow: 1 }}
            textAlign="center"
          />
          <CtaLink variant="yellow" url={linkUrl}>
            {linkLabel}
          </CtaLink>
          {isLast && <Span mt={4}>Yepp, I offer retainers too</Span>}
        </Box>
      </Box>
    </Box>
  )
}

const TellMeWhatYouWant = (props) => {
  const { heading, section } = props
  return (
    <Box
      as="article"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Heading
        as="h2"
        textAlign="center"
        fontSize={7}
        fontWeight="700"
        p={5}
        py={6}
        maxWidth="820px"
      >
        {heading}
      </Heading>
      <Flex
        position="relative"
        flexDirection="column"
        alignItems={['center', 'center', 'flex-start']}
      >
        {section.map((s, i) => (
          <Section
            key={i}
            section={s}
            numberOrder={i}
            isLast={i === section.length - 1}
          />
        ))}
      </Flex>
    </Box>
  )
}

export default TellMeWhatYouWant
