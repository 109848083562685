import React from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import AllLayouts from '../../components/AllLayouts'

/**
 *  pageContext from gatsby-node - passed in during page creation
 */
const Page = ({ pageContext }) => {
  const {
    page: { title, pageBuilder, uri, featuredImage, isFrontPage, seo },
  } = pageContext
  const layouts = pageBuilder.layouts || []

  const metaDesc = seo?.metaDesc

  /**
   * return a component with all layouts added in acf for given page
   */
  return (
    <Layout isFrontPage={isFrontPage}>
      <SEO
        title={title}
        description={metaDesc || ''}
        path={uri}
        image={featuredImage}
      />
      {layouts.map((layout, index) => {
        return <AllLayouts key={index} layoutData={layout} />
      })}
    </Layout>
  )
}

export default Page
