import React from 'react'
import styled from 'styled-components'
import { Box } from './Shared'

const StyledButton = styled(Box)`
  text-align: center;
  border: 2px transparent solid;
  padding: 1rem 1.5rem;
  letter-spacing: 0.08rem;
  white-space: nowrap;
  font-weight: 600;
  font-size: 0.9rem;

  color: ${(props) => props.color ?? '#fff'};

  min-width: 10rem;
  max-width: 25rem;

  background-color: ${(props) =>
    props.variant === 'blue'
      ? '#0B3EAF'
      : props.variant === 'orange'
      ? '#E96A4B'
      : '#F6B227'};

  &:disabled {
    cursor: not-allowed;
  }

  transition: all 0.3s ease;

  &:hover {
    border: 2px #fff solid;
    box-shadow:
      inset 4px -4px 4px black,
      inset 4px -4px 4px black;

    background: #1c2b39;

    * {
      color: white !important;
    }
  }

  a {
    text-decoration: none;
    color: ${(props) => (props.variant === 'blue' ? '#FFFFFF' : '#000000')};
  }
`

export const Button = ({ label, ...props }) => {
  return (
    <StyledButton as="button" {...props}>
      {label}
    </StyledButton>
  )
}

export default Button
