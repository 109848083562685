import React from 'react'
import styled from 'styled-components'
import FluidImage from '../../components/FluidImage'
import { Box, Heading, Spacer, Span, Text } from '../../components/Shared'
import pizzaSliceIcon from '../../images/pizzaPoint.svg'

const Wrapper = styled(Box)`
  outline: 5px solid #f7cebf;
  outline-offset: 50px;
`

const BulletListWrapper = styled(Box)`
  ul {
    list-style: none;
    padding-left: 1rem;
  }

  li {
    margin-top: 2rem;
    line-height: 1.35rem;
    &:before {
      content: '';
      display: inline-block;
      height: 1em;
      width: 1em;
      margin-left: -2.5em;
      background-image: url(${pizzaSliceIcon});
      background-size: contain;
      background-size: 20px 40px;
      /* hacks again */
      padding-bottom: 2rem;
      margin-bottom: -2rem;
      padding-top: 1rem;
      margin-top: -1rem;
      padding-right: 1.5em;
      margin-right: -1.5em;
      background-repeat: no-repeat;
      margin-right: 1em;
    }
  }
`

const WhatYouGetFromMe = ({ data }) => {
  const { heading, item } = data
  const copy = item[0].text
  return (
    <Wrapper as="section" maxWidth="700px" margin="0 auto">
      <Heading as="h3" mb={4} color="black">
        {heading}
      </Heading>
      <BulletListWrapper
        as="article"
        dangerouslySetInnerHTML={{ __html: copy }}
        maxWidth="900px"
        margin="0 auto"
      />
    </Wrapper>
  )
}

const ImageCopyWrapper = styled(Box)`
  p {
    margin-bottom: 2rem;
  }
`

const MeAsACopywriter = (props) => {
  const { heading, subheading, copy, disclaimer, whatYouGetFromMe, image } =
    props
  return (
    <Box
      as="section"
      bg="gallery"
      p={5}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading as="h2" fontSize={2} mb={2}>
        {heading}
      </Heading>
      <Heading as="h2">{subheading}</Heading>
      <ImageCopyWrapper
        as="article"
        display="flex"
        flexDirection={['column', 'column', 'row']}
        alignItems="center"
        maxWidth="1000px"
        margin="0 auto"
        my={5}
      >
        <FluidImage image={image} width={['100%', '100%', '50vw']} />
        <Text
          as="div"
          dangerouslySetInnerHTML={{ __html: copy }}
          maxWidth="600px"
          px={[2, 3, 5]}
          mt={[3, 3, 0]}
        />
      </ImageCopyWrapper>
      <Spacer my={4} />
      <WhatYouGetFromMe data={whatYouGetFromMe[0]} />
      <Spacer my={5} />
      <Span fontSize={3} textAlign="center">
        {disclaimer}
      </Span>
    </Box>
  )
}

export default MeAsACopywriter
