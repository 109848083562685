import React from 'react'
import styled from 'styled-components'
import { Box, Heading, Span, Text } from '../../components/Shared'
import FluidImage from '../../components/FluidImage'

const Copy = styled(Box)`
  margin: 0 auto;
  line-height: 1.89;
  p {
    color: #333333;
    margin-bottom: 1rem;
  }
  h3 {
    margin-bottom: 1rem;
  }

  //TODO: remove this!!
  p:last-of-type {
    display: none;
  }
`

const StyledHeading = styled(Heading)`
  h2,
  h3 {
    margin: 2rem 0;
  }
`

const MediaKit = (props) => {
  const { heading, images, downloadLogos, copy } = props
  return (
    <Box as="article" display="flex" flexDirection="column" alignItems="center">
      <StyledHeading
        as="section"
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <Box
        as="section"
        display="grid"
        p={5}
        gridTemplateColumns={[
          'repeat(1, 1fr)',
          'repeat(1, 1fr)',
          'repeat(2, 1fr)',
        ]}
        gridGap={5}
        maxWidth="1000px"
        margin="0 auto"
      >
        {images.map((image) => (
          <FluidImage image={image} imgStyle={{ objectFit: 'contain' }} />
        ))}
      </Box>
      <Copy
        p={5}
        maxWidth="750px"
        as="section"
        dangerouslySetInnerHTML={{ __html: copy }}
      />
      <Span>Click to download logo</Span>
      <Box as="section" display="flex" p={5} maxWidth="1000px" margin="0 auto">
        {downloadLogos.map((image) => (
          <FluidImage
            mx={3}
            image={image}
            imgStyle={{ objectFit: 'contain' }}
          />
        ))}
      </Box>
    </Box>
  )
}

export default MediaKit
