import React from 'react'
import styled from 'styled-components'
import { Box, Heading, Text, Span, Spacer } from '../../components/Shared'
import straightUnderline from '../../images/straightUnderline.svg'

const PhaseContainer = styled(Box)`
  #phase-heading {
    background: url(${straightUnderline});
    background-repeat: repeat-x;
    background-position: 0 100%;
    padding-bottom: 0.55rem;
  }

  ${Text} {
    p {
      margin-bottom: 1rem;
    }
  }
`
const Description = styled(Text)`
  h2,
  h3 {
    color: ${({ theme }) => theme.colors.toreaBay};
  }
`

const SectionWithPhases = (props) => {
  const { description, openLoop, phase } = props

  const plusPhases = []

  const regularPhases = []

  phase.forEach((p) => {
    if (p.plus) {
      plusPhases.push(p)
    } else {
      regularPhases.push(p)
    }
  })

  return (
    <Box
      as="article"
      bg="mandysPink"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      px={3}
      py={5}
    >
      <Description
        as="article"
        maxWidth="700px"
        textAlign="center"
        color="mineShaft"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Spacer my="3%" />
      {regularPhases.map((phase, index) => {
        const {
          description: phaseDescription,
          heading: phaseHeading,
          numbered,
        } = phase
        return (
          <PhaseContainer
            as="article"
            key={phaseHeading}
            maxWidth="800px"
            mb={3}
          >
            {numbered && (
              <Heading as="h3" fontSize={[4, 5, 6]} textAlign="left">
                Phase {index + 1}
              </Heading>
            )}
            <Heading
              id="phase-heading"
              as="h4"
              fontSize={[3, 4, 5]}
              textAlign="left"
            >
              {phaseHeading}
            </Heading>
            <Text
              as="div"
              textAlign="left"
              color="mineShaft"
              py={3}
              dangerouslySetInnerHTML={{ __html: phaseDescription }}
            />
          </PhaseContainer>
        )
      })}
      {plusPhases.length > 0 && (
        <>
          <Heading as="h3" fontSize={7} fontWeight={500} my={3}>
            PLUS
          </Heading>
          {plusPhases.map((phase, index) => {
            const {
              description: phaseDescription,
              heading: phaseHeading,
              numbered,
            } = phase
            return (
              <PhaseContainer
                as="article"
                key={phaseHeading}
                maxWidth="800px"
                mb={3}
              >
                {numbered && (
                  <Heading as="h3" fontSize={[4, 5, 6]} textAlign="left">
                    Phase {index + 1}
                  </Heading>
                )}
                <Heading
                  id="phase-heading"
                  as="h4"
                  fontSize={[3, 4, 5]}
                  textAlign="left"
                >
                  {phaseHeading}
                </Heading>
                <Text
                  as="div"
                  textAlign="left"
                  color="mineShaft"
                  py={3}
                  dangerouslySetInnerHTML={{ __html: phaseDescription }}
                />
              </PhaseContainer>
            )
          })}
        </>
      )}
      {openLoop && (
        <Span color="toreaBay" fontWeight="bold" fontSize={4} my={4}>
          {openLoop}
        </Span>
      )}
    </Box>
  )
}

export default SectionWithPhases
