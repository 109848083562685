import React from 'react'
import FluidImage from '../../components/FluidImage'
import { Box, Heading, Spacer } from '../../components/Shared'

const WorkShowcase = (props) => {
  const { heading, imageGallery } = props
  return (
    <Box
      as="article"
      p={5}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Heading as="h2">{heading}</Heading>
      <Spacer my="5%" />
      <Box
        display="grid"
        gridGap={5}
        gridTemplateColumns={[
          'repeat(1, 1fr)',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
        ]}
        maxWidth="1000px"
      >
        {imageGallery.map((image) => (
          <FluidImage
            key={image.databaseId}
            image={image}
            imgStyle={{ objectFit: 'contain' }}
          />
        ))}
      </Box>
    </Box>
  )
}

export default WorkShowcase
