import React from 'react'

import styled from 'styled-components'
import FluidImage from '../../components/FluidImage'
import { Box } from '../../components/Shared'

const ComponentWithImage = ({ image, copy, textColor }) => {
  return (
    <Box
      as="section"
      display="flex"
      flexDirection={['column', 'column', 'row']}
      alignItems="center"
      py={[0, 0, 5]}
      maxWidth="820px"
      margin="0 auto"
    >
      <FluidImage
        style={{ minHeight: '15rem', minWidth: '15rem' }}
        image={image}
      />
      {copy && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          color={textColor || 'toreaBay'}
          fontSize={7}
          p={[5, 5, 0]}
          ml={[0, 0, 5]}
          lineHeight={3}
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      )}
    </Box>
  )
}

export default ComponentWithImage
