import React from 'react'
import styled from 'styled-components'
import { Box, Heading, Text } from '../../components/Shared'
import * as Accordion from '@radix-ui/react-accordion'
import { ChevronDownIcon } from '@radix-ui/react-icons'

const Column = styled(Box)`
  p {
    margin: 1.5rem 0;
    color: #333333;
    line-height: 1.89;
  }
`

const AccordionQuestion = styled(Accordion.Trigger)`
  display: block;
  span {
    text-align: left;
    svg {
      margin-left: 0.5rem;
    }
  }

  border: none;
  background-color: inherit;
  font-size: 1.125rem;
  text-align: left;
  font-weight: bold;
  color: inherit;
`

const AccordionWrapper = styled(Accordion.Root)`
  max-width: 800px;
  margin: 24px;
`
const ChevronWrapper = styled(ChevronDownIcon)`
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  [data-state='open'] {
    transform: rotate(180deg);
  }
  height: 24px;
  width: 24px;
`

const PurchaseFAQ = (props) => {
  const { heading, items } = props
  return (
    <Box
      as="article"
      bg="mandysPink"
      p={5}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading as="h2" marginBottom="40px" maxWidth="200px">
        {heading}
      </Heading>
      <Box display="grid" gridTemplateColumns="repeat(auto-fill, 1fr)">
        {items[0].questionAndAnswer.map((item, index) => {
          return (
            <AccordionWrapper type="single" collapsible key={index}>
              <Accordion.Item value={`item-${index + 1}`}>
                <Accordion.Header>
                  <AccordionQuestion>
                    <Heading as="span">
                      {item.question}
                      <ChevronWrapper color="#0B3EAF" aria-hidden />
                    </Heading>
                  </AccordionQuestion>
                </Accordion.Header>
                <Accordion.Content>
                  <Column dangerouslySetInnerHTML={{ __html: item.answer }} />
                </Accordion.Content>
              </Accordion.Item>
            </AccordionWrapper>
          )
        })}
      </Box>
    </Box>
  )
}

export default PurchaseFAQ
