import React from 'react'
import FluidImage from '../../components/FluidImage'
import { Box } from '../../components/Shared'

const ImageGallery = (props) => {
  const { images, variant } = props

  if (variant === 'testimonials') {
    return (
      <Box
        as="article"
        display="grid"
        gridGap={[0, 4, 4]}
        border={0}
        p={5}
        maxWidth="900px"
        margin="0 auto"
        gridTemplateColumns={[
          'repeat(1, 1fr)',
          'repeat(1, 1fr)',
          'repeat(2, 1fr)',
        ]}
      >
        {images.map((image) => (
          <FluidImage
            image={image}
            maxHeight="1000px"
            imgStyle={{ objectFit: 'contain' }}
          />
        ))}
      </Box>
    )
  }

  return (
    <Box as="article" display="flex" width="100%">
      {images.map((image) => (
        <FluidImage
          image={image}
          width="100%"
          imgStyle={{ objectPosition: 'left' }}
          maxWidth={images.length > 1 ? '50vw' : null}
          maxHeight="1000px"
        />
      ))}
    </Box>
  )
}

export default ImageGallery
