import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { CtaLink } from '../../components/LinkCta'
import { Box, Heading } from '../../components/Shared'
import signature from '../../images/SignedMessage.svg'

const HeroContent = styled(Box)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 50vw;
    top: 50%;
    left: 60%;
    transform: translate(-30%, -50%);
  }
`
const Signature = styled.img`
  position: absolute;
  bottom: 5%;
  right: 10%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    transform: scale(0.6);
    right: 0%;
  }
`

const Hero = (props) => {
  const { featuredCopy, heading, linkLabel, linkUrl } = props

  return (
    <Box
      as="section"
      position="relative"
      display="flex"
      flexDirection={['column', 'column', 'row']}
    >
      <Box width={'100%'}>
        <Box position="relative">
          <StaticImage
            quality={100}
            src="../../images/eman-ismail-hero-min.jpg"
            layout="fullWidth"
            style={{
              opacity: 0.95,
              filter: 'brightness(0.80)',
            }}
            placeholder="blurred"
            alt="eman-ismail-hero"
          />
          <Signature src={signature} alt="I'm so glad you're here!" />
        </Box>
        <HeroContent
          position={['static', 'static', 'absolute']}
          textAlign="center"
          fontFamily="libreFranklin"
          bg={['mandysPink', 'mandysPink', 'inherit']}
        >
          <Heading
            fontSize={[6, 6, 8]}
            fontWeight={800}
            dangerouslySetInnerHTML={{ __html: heading }}
            p={[4, 4, 1]}
            // my={3}
            color={['burntSienna', 'burntSienna', 'whiteSmoke']}
          />
          <Box
            position="relative"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={[5, 4, 0]}
            bg={['mandysPink', 'mandysPink', 'inherit']}
          >
            <Box
              lineHeight={4}
              fontSize={[4, 4, 5]}
              color="toreaBay"
              dangerouslySetInnerHTML={{
                __html: featuredCopy,
              }}
            />
            {linkLabel && linkUrl ? (
              <CtaLink my={4} url={linkUrl} variant="blue">
                {linkLabel}
              </CtaLink>
            ) : null}
          </Box>
        </HeroContent>
      </Box>
    </Box>
  )
}

export default Hero
