import React from 'react'
import styled from 'styled-components'

import FluidImage from '../../components/FluidImage'
import { Box, Text } from '../../components/Shared'
import pizzaSliceIcon from '../../images/pizzaPoint.svg'

const Copy = styled(Text)`
  flex: 70%;
  p,
  h2,
  h3,
  ul {
    max-width: 600px;
  }

  h2 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  h2,
  h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.colors.toreaBay};
    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
      text-align: center;
    }
  }

  ul {
    list-style: none;
    padding-left: 1rem;
    margin: 2rem 0;
  }

  li {
    margin-top: 2rem;
    line-height: 1.35rem;
    &:before {
      content: '';
      display: inline-block;
      height: 1em;
      width: 1em;
      margin-left: -2.5em;
      background-image: url(${pizzaSliceIcon});
      background-size: contain;
      background-size: 20px 40px;
      /* hacks again */
      padding-bottom: 2rem;
      margin-bottom: -2rem;
      padding-top: 1rem;
      margin-top: -1rem;
      padding-right: 1.5em;
      margin-right: -1.5em;
      background-repeat: no-repeat;
      margin-right: 1em;
    }
  }
`

const HalfCopyHalfImage = (props) => {
  const { copy, image, backgroundColor } = props

  return (
    <Box
      as="article"
      display="flex"
      flexDirection={['column', 'column', 'row']}
      overflow="hidden"
      width="100%"
    >
      <Copy
        as="article"
        order={[2, 2, 1]}
        dangerouslySetInnerHTML={{ __html: copy }}
        bg={backgroundColor || 'mandysPink'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={5}
      />
      <FluidImage
        image={image}
        display="flex"
        order={[1, 1, 2]}
        // maxHeight="1000px"
        maxWidth="50vw"
        imgStyle={{ objectFit: 'cover', objectPosition: 'top' }}
      />
    </Box>
  )
}

export default HalfCopyHalfImage
