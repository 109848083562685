import React from 'react'
import styled from 'styled-components'
import { Box, Heading, Spacer } from '../../components/Shared'
import CtaLink from '../../components/LinkCta'

const Banner = styled(Box)`
  h2,
  h3 {
    color: white;
    text-align: center;
    max-width: 885px;
  }
`

const HeadingBanner = (props) => {
  const { heading, subheadingTop, subheadingBottom } = props
  const cta = props.cta ? props.cta[0] : {}

  return (
    <Banner
      as="section"
      bg="toreaBay"
      display="flex"
      flexDirection="column"
      alignItems="center"
      px="10vw"
      py="10vh"
    >
      {subheadingTop && (
        <Heading as="h3" fontSize={6} fontWeight={350}>
          {subheadingTop}
        </Heading>
      )}
      <Heading as="h2" fontSize={8} lineHeight={3} my={4}>
        {heading}
      </Heading>
      {subheadingBottom && (
        <Heading as="h3" fontSize={6} fontWeight={350}>
          {subheadingBottom}
        </Heading>
      )}
      <Spacer my={4} />
      {cta?.linkLabel && (
        <CtaLink url={cta.linkUrl} variant={cta.linkVariant || 'blue'}>
          {cta.linkLabel}
        </CtaLink>
      )}
    </Banner>
  )
}
export default HeadingBanner
