import React from 'react'
import styled from 'styled-components'
import FluidImage from '../../components/FluidImage'
import CtaLink from '../../components/LinkCta'
import { Box, Heading, Span, Text } from '../../components/Shared'
import straightUnderline from '../../images/straightUnderline.svg'

const HeadingUnderlined = styled(Heading)`
  max-height: 11rem;
  padding-bottom: 1rem;
  background: url(${straightUnderline});
  background-repeat: repeat-x;
  background-position: 0 90%;
`

const Endorsement = ({ data }) => {
  const { heading, lead, secondHeading, testimony, linkText, linkUrl } = data

  const { content, image, name, organisation } = testimony[0]

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <HeadingUnderlined as="h3" fontSize={8} fontWeight={400} maxWidth="700px">
        {heading}
      </HeadingUnderlined>
      <Heading as="h3" py={3} maxWidth="400px">
        {secondHeading}
      </Heading>
      <Text maxWidth="700px">{lead}</Text>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={5}
      >
        <FluidImage
          imgStyle={{
            borderRadius: '50%',
          }}
          style={{
            width: '12rem',
            height: '12rem',
          }}
          image={image}
          alt={name}
        />
        <Text
          as="div"
          dangerouslySetInnerHTML={{ __html: content }}
          lineHeight={3}
          my={4}
          fontWeight={500}
          maxWidth="450px"
        />
        <Span letterSpacing="0.05rem" ontSize={2} mb={5} opacity={0.7}>
          —{name}, {organisation}
        </Span>
        {linkUrl ? (
          <CtaLink url={linkUrl} variant="orange">
            {linkText}
          </CtaLink>
        ) : null}
      </Box>
    </Box>
  )
}

const LearnCopywriter = (props) => {
  const { workshopEndorsement } = props
  return (
    <Box
      as="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={[4, 6, 5]}
    >
      <Endorsement data={workshopEndorsement[0]} />
    </Box>
  )
}

export default LearnCopywriter
