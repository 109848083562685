import React from 'react'
import FluidImage from '../../components/FluidImage'
import { Box, Heading, Text } from '../../components/Shared'
import { CtaLink } from '../../components/LinkCta'

const CtaWithImage = (props) => {
  const {
    heading,
    imagePlacement,
    image,
    text,
    backgroundColor,
    linkLabel,
    linkUrl,
    textColor,
  } = props

  const rightAligned = imagePlacement.toLowerCase() === 'right'

  return (
    <Box as="section" bg={backgroundColor}>
      <Box
        display="flex"
        flexDirection={[
          'column',
          'column',
          !rightAligned ? 'row' : 'row-reverse',
        ]}
        alignItems={[null, null, 'flex-start']}
        width="100%"
        maxWidth="1400px"
        margin="0 auto"
        p={[0, 0, 5]}
      >
        <FluidImage
          image={image}
          style={{ flex: '0 0 50%' }}
          mb={['2rem', '2rem', 0]}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          p={5}
          pt={0}
        >
          <Heading
            as="h2"
            fontSize={6}
            pb={2}
            color={textColor === '#ffffff' ? 'white' : 'toreaBay'}
          >
            {heading}
          </Heading>
          <Text color={textColor || 'white'} pb={4}>
            {text}
          </Text>
          <CtaLink url={linkUrl} variant="yellow">
            {linkLabel}
          </CtaLink>
        </Box>
      </Box>
    </Box>
  )
}

export default CtaWithImage
