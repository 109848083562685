import React from 'react'
import styled from 'styled-components'
import { variant } from 'styled-system'
import { UniversalLink } from './UniversalLink'

import {
  color,
  space,
  typography,
  flexbox,
  background,
  border,
  position,
  zIndex,
  maxWidth,
} from 'styled-system'

const wrapperColorVariants = variant({
  variants: {
    orange: {
      color: 'white',
      bg: 'burntSienna',
    },
    blue: {
      color: 'white',
      bg: 'toreaBay',
    },
    yellow: {
      color: 'white',
      bg: 'saffron',
    },
  },
})

const linkColorVariants = variant({
  variants: {
    orange: {
      color: 'white',
    },
    blue: {
      color: 'white',
    },
    yellow: {
      color: 'black',
    },
  },
})

const StyledUniversalLink = styled(UniversalLink)`
  ${color}
  ${space}
  ${typography}
  ${flexbox}
  ${background}
  ${border}
  ${position}
  ${zIndex}
  ${maxWidth}

  display: block;
  cursor: pointer;
  text-decoration: none;

  text-transform: 'uppercase';

  ${wrapperColorVariants}

  text-align: center;

  border: 2px transparent solid;

  padding: 1rem;

  letter-spacing: 0.08rem;
  font-weight: 600;
  font-size: 0.9rem;

  transition: all 0.3s ease;

  &:disabled {
    cursor: not-allowed;
  }

  &:after {
    display: inline-block;
    content: '\\2192'; // arrow right unicode
    font-weight: bold;
    transition: all 0.3s ease-out;
    color: transparent;
  }

  &:hover {
    text-decoration: underline;
    box-shadow:
      inset 4px -4px 4px #09389d,
      inset 4px -4px 4px #09389d;
    background: ${({ theme }) => theme.colors.toreaBay};

    color: white;

    &:after {
      padding: 0 8px;
      transform: translateX(5px);
      color: white;
    }
  }

  text-decoration: none;
  text-transform: uppercase;
  ${linkColorVariants};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    white-space: nowrap;
  }
`

export const CtaLink = ({ children, url, ...props }) => {
  if (!children) {
    return null
  }
  return (
    <StyledUniversalLink to={url} {...props}>
      {children}
    </StyledUniversalLink>
  )
}

export default CtaLink
