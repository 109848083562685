import React, { useState } from 'react'
import { withFormik, Field } from 'formik'
import * as Yup from 'yup'
import { Box, Spacer } from '../Shared'
import Button from '../Button'
import styled from 'styled-components'

const FieldWrapper = styled(Box)`
  display: flex;
  flex-direction: column;

  label,
  p {
    margin: 0.5rem 0;
  }

  label {
    font-weight: 500;
  }
`

const StyledField = styled(Field)`
  height: 1.5rem;
  width: 15rem;
  border: 5px solid #fff;
  padding: 1rem 0.75rem;
  font-size: 1.1rem;
  background-color: ${({ theme }) => theme.colors.mandysPink};
`

const SubConfirm = () => {
  return (
    <Box>
      <h1>
        <strong>THANK YOU</strong> for joining my mailing list!
        <br />
        Check your inbox for a confirmation.
      </h1>
    </Box>
  )
}

export const EmailListForm = ({ errors, touched }) => {
  const [formSubmitted, setFormSubmitted] = useState(false)

  const SUBMISSION_MESSAGE_TIMEOUT = 8000

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.target)

    fetch('https://inkhouse.activehosted.com/proc.php', {
      method: 'POST',
      body: data,
      mode: 'no-cors',
    })
      .then((_) => {
        setFormSubmitted(true)
        setTimeout(() => {
          setFormSubmitted(false)
        }, SUBMISSION_MESSAGE_TIMEOUT)
      })
      .catch((error) => console.log('Request failed', error))
  }

  return (
    <form onSubmit={handleSubmit}>
      {/* ActiveCampaign support */}
      <input type="hidden" name="u" value="1" />
      <input type="hidden" name="f" value="1" />
      <input type="hidden" name="s" />
      <input type="hidden" name="c" value="0" />
      <input type="hidden" name="m" value="0" />
      <input type="hidden" name="act" value="sub" />
      <input type="hidden" name="v" value="2" />

      {!formSubmitted ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            as="fieldset"
            display="grid"
            gridGap={[0, 5, 5]}
            border={0}
            gridTemplateColumns={[
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
              'repeat(2, 1fr)',
            ]}
          >
            <FieldWrapper>
              <label htmlFor="mailing_list_recipient_name">Name</label>
              <StyledField type="text" name="name" />
              {touched.name && errors.name && <p>{errors.name}</p>}
            </FieldWrapper>
            <FieldWrapper>
              <label htmlFor="mailing_list_recipient_email">
                Email Address
              </label>
              <StyledField type="email" name="email" />
              {touched.email && errors.email && <p>{errors.email}</p>}
            </FieldWrapper>
          </Box>
          {(errors.name || errors.email) && (
            <p style={{ marginTop: '10px' }}>
              Please fill in all the required fields 🙏
            </p>
          )}
          <Spacer my={3} />
          <Button
            type="submit"
            value="Submit"
            disabled
            label="I WANT UPDATES"
            variant="orange"
          />
        </Box>
      ) : (
        <SubConfirm />
      )}
    </form>
  )
}

export const NewsletterForm = withFormik({
  // reset the form if the wrapped component props change (using deep equality)
  enableReinitialize: true,
  mapPropsToValues({ named, email }) {
    return {
      name: named || '',
      email: email || '',
    }
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Please fill this field in.'),
    email: Yup.string()
      .email('Invalid email')
      .required('Please fill this field in.'),
  }),
})(EmailListForm)

export default NewsletterForm
