/**
 * This is a component for rendering one of the Layout custom fields
 * (e.g. hero) as a component
 */

import React from 'react'
import PropTypes from 'prop-types'
import Hero from '../layouts/Hero'
import Brands from '../layouts/Brands/'
import ComponentSimple from '../layouts/ComponentSimple/'
import ComponentIllustrated from '../layouts/ComponentIllustrated/'
import ComponentWithImage from '../layouts/ComponentWithImage'
import Testimonials from '../layouts/Testimonials'
import StandoutTestimonial from '../layouts/StandoutTestimonial'
import SimpleCta from '../layouts/SimpleCta'
import BlogPostPreview from '../layouts/BlogPostPreview'
import PodcastAppearance from '../layouts/PodcastAppearance'
import HalfCopyHalfImage from '../layouts/HalfCopyHalfImage'
import AboutMe from '../layouts/AboutMe'
import HeadingBanner from '../layouts/HeadingBanner'
import LearnBusinessOwner from '../layouts/LearnBusinessOwner'
import LearnCopywriter from '../layouts/LearnCopywriter'
import Newsletter from '../layouts/Newsletter'
import CtaWithImage from '../layouts/CtaWithImage'
import CallToWorkWithMe from '../layouts/CallToWorkWithMe'
import SectionWithPhases from '../layouts/SectionWithPhases'
import MeAsACopywriter from '../layouts/MeAsACopywriter'
import TellMeWhatYouWant from '../layouts/TellMeWhatYouWant'
import SingularTestimonial from '../layouts/SingularTestimonial'
import HeadingCopyImage from '../layouts/HeadingCopyImage'
import PurchaseFAQ from '../layouts/PurchaseFAQ'
import VideoTestimonial from '../layouts/VideoTestimonial'
import ImageGallery from '../layouts/ImageGallery'
import MediaKit from '../layouts/MediaKit'
import WorkShowcase from '../layouts/WorkShowcase'
import LegalSection from '../layouts/LegalSection'

const AllLayouts = ({ layoutData }) => {
  /**
   * fieldGroupName determines which component to render
   */
  const layoutType = layoutData.fieldGroupName

  /**
   * Default component
   */
  const Default = () => (
    <article>
      You have no component mapping for the component: {layoutType}
    </article>
  )

  /**
   * Mapping the fieldGroupName(s) to our components
   */
  const layouts = {
    page_Pagebuilder_Layouts_HomeHero: Hero,
    page_Pagebuilder_Layouts_Brands: Brands,
    page_Pagebuilder_Layouts_ComponentSimple: ComponentSimple,
    page_Pagebuilder_Layouts_ComponentWithImage: ComponentWithImage,
    page_Pagebuilder_Layouts_ComponentIllustrated: ComponentIllustrated,
    page_Pagebuilder_Layouts_Testimonials: Testimonials,
    page_Pagebuilder_Layouts_StandoutTestimonial: StandoutTestimonial,
    page_Pagebuilder_Layouts_CtaSection: SimpleCta,
    page_Pagebuilder_Layouts_BlogPostPreview: BlogPostPreview,
    page_Pagebuilder_Layouts_PodcastAppearance: PodcastAppearance,
    page_Pagebuilder_Layouts_HalfCopyHalfImage: HalfCopyHalfImage,
    page_Pagebuilder_Layouts_AboutMe: AboutMe,
    page_Pagebuilder_Layouts_HeadingBanner: HeadingBanner,
    page_Pagebuilder_Layouts_LearnBusinessOwner: LearnBusinessOwner,
    page_Pagebuilder_Layouts_LearnCopywriter: LearnCopywriter,
    page_Pagebuilder_Layouts_Newsletter: Newsletter,
    page_Pagebuilder_Layouts_CtaWithImage: CtaWithImage,
    page_Pagebuilder_Layouts_CallToWorkWithMe: CallToWorkWithMe,
    page_Pagebuilder_Layouts_SectionWithPhases: SectionWithPhases,
    page_Pagebuilder_Layouts_MeAsACopywriter: MeAsACopywriter,
    page_Pagebuilder_Layouts_TellMeWhatYouWant: TellMeWhatYouWant,
    page_Pagebuilder_Layouts_HeadingCopyImage: HeadingCopyImage,
    page_Pagebuilder_Layouts_SingularTestimonial: SingularTestimonial,
    page_Pagebuilder_Layouts_PurchaseFaq: PurchaseFAQ,
    page_Pagebuilder_Layouts_VideoTestimonial: VideoTestimonial,
    page_Pagebuilder_Layouts_ImageGallery: ImageGallery,
    page_Pagebuilder_Layouts_MediaKit: MediaKit,
    page_Pagebuilder_Layouts_WorkShowcase: WorkShowcase,
    page_Pagebuilder_Layouts_LegalSection: LegalSection,
    page_default: Default,
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   * The expression below functions like/could be replaced w a switch statement
   */
  const ComponentTag = layouts[layoutType]
    ? layouts[layoutType]
    : layouts['page_default']

  return <ComponentTag {...layoutData} />
}

AllLayouts.propTypes = {
  layoutData: PropTypes.object,
}

export default AllLayouts
