import styled from 'styled-components'
import Box from './Box'
import pizzaSliceIcon from '../../images/pizzaPoint.svg'

export const WysiwygWrapper = styled(Box)`
  p {
    padding: 1rem 0;
  }

  ul {
    list-style: none;
    padding-left: 1rem;
    color: ${(props) => props.color || 'mineShaft'};
  }

  li {
    margin: 1rem 0;
    line-height: 1.89;
    /* line-height: 1.35rem; */
    &:before {
      content: '';
      display: inline-block;
      height: 1em;
      width: 1em;
      margin-left: -2.5em;
      background-image: url(${pizzaSliceIcon});
      background-size: contain;
      background-size: 20px 40px;
      /* hacks again */
      padding-bottom: 2rem;
      margin-bottom: -2rem;
      padding-top: 1rem;
      margin-top: -1rem;
      padding-right: 1.5em;
      margin-right: -1.5em;
      background-repeat: no-repeat;
      margin-right: 1em;
    }
  }
`
