import React from 'react'

import FluidImage from '../../components/FluidImage'
import { Box, Column, Heading, Spacer, Span } from '../../components/Shared'

const Testimonial = ({ item }) => {
  const { name, organisation, content, image } = item

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      m={4}
    >
      <FluidImage
        style={{
          maxWidth: '200px',
          marginBottom: '15%',
        }}
        imgStyle={{
          borderRadius: '50%',
          boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.75) inset',
        }}
        image={image}
        alt={name}
      />
      <Box maxWidth="300px">
        <Box dangerouslySetInnerHTML={{ __html: content }} />
        <Spacer my={4} />
        <Span letterSpacing="0.05rem">
          —{name}, {organisation}
        </Span>
      </Box>
    </Box>
  )
}

const Testimonials = ({ testimony, heading, backgroundColor }) => {
  return (
    <Column
      as="section"
      position="relative"
      bg={backgroundColor || 'gallery'}
      p={[2, 4, 6]}
      py={[6, 6, 6]}
    >
      {heading && (
        <Heading
          as="h3"
          color="toreaBay"
          my={5}
          textAlign="center"
          fontSize={[4, 4, 5]}
        >
          {heading}
        </Heading>
      )}
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        alignItems={['center', 'center', 'flex-start']}
        justifyContent="space-between"
        width="80%"
        maxWidth="900px"
      >
        {testimony.map((t) => (
          <Testimonial key={t.name} item={t} />
        ))}
      </Box>
    </Column>
  )
}

export default Testimonials
