import React from 'react'
import styled from 'styled-components'
import FluidImage from '../../components/FluidImage'
import CtaLink from '../../components/LinkCta'
import { Box, Heading, Span, Text } from '../../components/Shared'
import straightUnderline from '../../images/straightUnderline.svg'

const HeadingUnderlined = styled(Heading)`
  max-height: 11rem;
  padding-bottom: 1rem;
  background: url(${straightUnderline});
  background-repeat: repeat-x;
  background-position: 0 90%;
`

const TestimonialWrapper = styled(Text)`
  span {
    font-weight: 500 !important;
  }
`

const Endorsement = ({ data }) => {
  const { heading, lead, secondHeading, testimony, linkUrl, linkText } = data

  const { content, image, name, organisation } = testimony[0]

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <HeadingUnderlined as="h3" fontSize={8} fontWeight={400} maxWidth="700px">
        {heading}
      </HeadingUnderlined>
      <Heading as="h3" py={3} maxWidth="400px">
        {secondHeading}
      </Heading>
      <Text maxWidth="700px">{lead}</Text>
      <Box p={5} display="flex" alignItems="center" justifyContent="center">
        <FluidImage
          imgStyle={{
            borderRadius: '50%',
          }}
          style={{
            maxWidth: '12rem',
            height: '12rem',
          }}
          image={image}
          alt={name}
        />
      </Box>
      <TestimonialWrapper
        as="div"
        maxWidth="700px"
        fontSize={3}
        dangerouslySetInnerHTML={{ __html: content }}
        mb={5}
      />
      <Span letterSpacing="0.05rem" fontSize={2} mb={5} opacity={0.7}>
        —{name}, {organisation}
      </Span>
      {linkUrl ? (
        <CtaLink url={linkUrl} variant="orange">
          {linkText}
        </CtaLink>
      ) : null}
    </Box>
  )
}

const LearnBusinessOwner = (props) => {
  const { workshopEndorsement } = props
  return (
    <Box
      as="section"
      bg="mandysPink"
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={[4, 6, 5]}
    >
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        {workshopEndorsement.map((endorsement) => (
          <Endorsement data={endorsement} />
        ))}
      </Box>
    </Box>
  )
}

export default LearnBusinessOwner
