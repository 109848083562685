import React from 'react'
import FluidImage from '../../components/FluidImage'
import { Box, Heading, Row } from '../../components/Shared'
import UniversalLink from '../../components/UniversalLink'

const PodcastAppearance = (props) => {
  const { heading, thumbnail, backgroundColor } = props

  return (
    <Box as="section" position="relative" p={5} bg={backgroundColor || '#fff'}>
      <Heading as="h2" textAlign="center" pb={5}>
        {heading}
      </Heading>
      <Row flexWrap="wrap">
        {thumbnail.map((t) => {
          const { link, image } = t
          if (link) {
            return (
              <UniversalLink key={image.databaseId} to={link}>
                <FluidImage
                  image={image}
                  alt={link}
                  maxWidth="250px"
                  m={[2, 2, 2]}
                  aria-label={`Link to the podcast: ${link}`}
                />
              </UniversalLink>
            )
          } else {
            return (
              <FluidImage
                image={image}
                alt={link}
                maxWidth="250px"
                m={[2, 2, 2]}
              />
            )
          }
        })}
      </Row>
    </Box>
  )
}

export default PodcastAppearance
