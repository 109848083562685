import React from 'react'
import styled from 'styled-components'
import FluidImage from '../../components/FluidImage'
import { Box, Heading, Spacer } from '../../components/Shared'
import { WysiwygWrapper } from '../../components/Shared/WysiwygWrapper'

const Wrapper = styled(Box)`
  * {
    max-width: 1000px;
  }
  line-height: 1.89;
  p {
    padding-top: 0 !important;
  }
`

const HeadingCopyImage = (props) => {
  const { imagePlacement, image, heading, copy, backgroundColor, textColor } =
    props

  const rightAligned = imagePlacement.toLowerCase() === 'right'

  return (
    <Wrapper
      as="article"
      display="flex"
      flexDirection="column"
      alignItems="center"
      bg={backgroundColor}
      p={5}
    >
      <Heading
        as="h2"
        color={textColor || 'toreaBay'}
        maxWidth="930px"
        fontSize={[4, 5, 6]}
      >
        {heading}
      </Heading>
      <Spacer my="2%" />
      <Box
        display="flex"
        flexDirection={[
          'column',
          'column',
          !rightAligned ? 'row' : 'row-reverse',
        ]}
        alignItems={[null, null, 'flex-start']}
      >
        <FluidImage
          image={image}
          height="100%"
          style={{ width: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
          mb={['2rem', '2rem', 0]}
        />
        <Spacer mx="6%" />
        <WysiwygWrapper
          as="article"
          color={textColor || 'mineShaft'}
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      </Box>
    </Wrapper>
  )
}

export default HeadingCopyImage
