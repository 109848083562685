import React from 'react'
import styled from 'styled-components'
import FluidImage from '../../components/FluidImage'
import { Box, Text } from '../../components/Shared'

const Avatar = styled(FluidImage)`
  border-radius: 50%;
`
const Testimonial = styled(Text)`
  p:not(:last-child) {
    margin-bottom: 1rem;
  }
  p:last-child {
    padding-top: 1rem;
    margin-top: auto;
    opacity: 0.7;
    font-weight: 300;
  }
`

const SingularTestimonial = (props) => {
  const { copy, image, backgroundColor, imagePlacement } = props

  return (
    <Box
      as="article"
      height="100%"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      margin="0 auto"
      alignItems="center"
      py={5}
      bg={backgroundColor}
    >
      <Avatar
        image={image}
        width="14rem"
        height="14rem"
        order={[0, 0, imagePlacement === 'right' ? 1 : 0]}
      />
      <Testimonial
        as="article"
        dangerouslySetInnerHTML={{ __html: copy }}
        maxWidth="860px"
        px={5}
        mt={[5, 5, 0]}
      />
    </Box>
  )
}

export default SingularTestimonial
