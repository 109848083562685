import React from 'react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import {
  color,
  space,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  zIndex,
  maxWidth,
  shadow,
  grid,
} from 'styled-system'

const StyledGatsbyImage = styled(GatsbyImage)`
  ${color}
  ${space}
  ${typography}
  ${layout}
  ${flexbox}
  ${background}
  ${border}
  ${position}
  ${zIndex}
  ${maxWidth}
  ${shadow}
  ${grid}
`

const FluidImage = ({ image, alt, withFallback = true, ...props }) => {
  /**
   * Return fallback image, if no image is given.
   */
  if (!image) {
    return (
      withFallback && (
        <StaticImage
          src="../images/fallback.svg"
          alt={'A blank fallback image for a missing one.'}
        />
      )
    )
  }

  if (image && image.localFile) {
    return (
      <StyledGatsbyImage
        image={image.localFile.childImageSharp.gatsbyImageData}
        alt={alt || image.altText}
        {...props}
      />
    )
  }

  return <img src={image.sourceUrl} alt={alt || image.altText} {...props} />
}

export default FluidImage
