import React from 'react'
import NewsletterForm from '../../components/EmailListForm'
import { Box, Heading, Text } from '../../components/Shared'

const Newsletter = (props) => {
  const { heading, message, unsubscribeMessage } = props
  return (
    <Box
      as="section"
      bg="mandysPink"
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={5}
    >
      <Heading
        as="h2"
        color="burntSienna"
        py={4}
        fontSize={8}
        textAlign="center"
      >
        {heading}
      </Heading>
      <Text
        py={4}
        fontSize={5}
        width="70%"
        textAlign="center"
        color="toreaBay"
        fontWeight={700}
        lineHeight={1}
        maxWidth="800px"
      >
        {message}
      </Text>
      <NewsletterForm />
      <Text fontStyle="italic" my={5} width="40%" textAlign="center">
        {unsubscribeMessage}
      </Text>
    </Box>
  )
}

export default Newsletter
