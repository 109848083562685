import React from 'react'
import styled from 'styled-components'
import FluidImage from '../../components/FluidImage'
import { Box, Column, Heading, Spacer, Text } from '../../components/Shared'
import { CtaLink } from '../../components/LinkCta'
import UniversalLink from '../../components/UniversalLink'
import { blogURI } from '../../../globals'

const Figcaption = styled.figcaption`
  max-width: 80%;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.toreaBay};
`

export const BlogPostPreview = (props) => {
  const { heading, lead, linkText, previewItem } = props

  return (
    <Column as="section" bg="mandysPink" p={5}>
      <Heading as="h2" color="toreaBay" maxWidth="1000px" fontSize={6} my={4}>
        {heading}
      </Heading>
      <Text maxWidth="900px" textAlign="center" fontSize={[1, 2, 3]}>
        {lead}
      </Text>
      <Spacer my={4} />
      <Box
        display="flex"
        alignItems={['center', 'center', 'flex-start']}
        flexDirection={['column', 'column', 'row']}
        width="95%"
        maxWidth="1500px"
      >
        {previewItem.map((item) => {
          const {
            postLink: { featuredImage, id, title, uri },
          } = item
          return (
            <Box
              key={id}
              p={[2, 3, 4]}
              py={[0, 0, 4]}
              position="relative"
              display="flex"
              flexDirection="column"
              flex={1}
              width="100%"
              maxWidth="50vw"
            >
              <UniversalLink to={`/${blogURI}${uri}`}>
                <FluidImage
                  image={featuredImage.node}
                  imgStyle={{ objectFit: 'contain' }}
                  aria-label={uri}
                />
              </UniversalLink>
              <Spacer mt={3} />
              <Figcaption>{title}</Figcaption>
              <Spacer my={3} />
              <CtaLink my={4} url={`/${blogURI}${uri}`} variant="yellow">
                {linkText}
              </CtaLink>
            </Box>
          )
        })}
      </Box>
    </Column>
  )
}

export default BlogPostPreview
