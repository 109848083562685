import React from 'react'
import FluidImage from '../../components/FluidImage'
import { Box, Heading, Text } from '../../components/Shared'
import { CtaLink } from '../../components/LinkCta'
import styled from 'styled-components'

const CopyBlock = styled(Text)`
  p {
    padding: 1rem 0;
  }
`

const CallToWorkWithMe = (props) => {
  const {
    heading,
    firstCopyBlock,
    image,
    subheading,
    secondCopyBlock,
    linkLabel,
    linkUrl,
  } = props

  return (
    <Box
      as="section"
      bg="gallery"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={5}
    >
      <Heading as="h2" maxWidth="900px" fontSize={6} textAlign="center" py={3}>
        {heading}
      </Heading>
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        maxWidth="800px"
        py={3}
      >
        <FluidImage image={image} width="100%" mb={5} />
        <CopyBlock
          as="div"
          width={['100%', '100%', '50vw']}
          ml={[0, 0, 4]}
          dangerouslySetInnerHTML={{ __html: firstCopyBlock }}
        />
      </Box>
      <Heading as="h3" fontSize={5} maxWidth="900px" textAlign="center" py={3}>
        {subheading}
      </Heading>
      <CopyBlock
        as="div"
        maxWidth="800px"
        mb={5}
        dangerouslySetInnerHTML={{ __html: secondCopyBlock }}
      />
      <CtaLink variant="yellow" url={linkUrl}>
        {linkLabel}
      </CtaLink>
    </Box>
  )
}

export default CallToWorkWithMe
