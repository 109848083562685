import React from 'react'
import styled from 'styled-components'
import CtaLink from '../../components/LinkCta'

import { Box, Spacer } from '../../components/Shared'
import { WysiwygWrapper } from '../../components/Shared/WysiwygWrapper'
import underline from '../../images/underline.svg'

const Content = styled(WysiwygWrapper)`
  h1 {
    margin: 3rem 0;
    color: #333333;
    font-size: 2.5rem;
  }

  h2,
  h3,
  h4,
  h5 {
    color: ${({ headingColor }) => headingColor};
    text-align: center;
  }

  h2 {
    font-size: 1.5rem;
  }

  span,
  em {
    padding: 1rem 0;
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      background: url(${underline});
      background-repeat: no-repeat;
      background-position: 0px 65px;
      padding-left: 2rem;
      margin-left: -2rem;
      padding-right: 2rem;
      margin-right: -2rem;
      padding-bottom: 2rem;
      margin-bottom: -2rem;
    }
  }
`

const ComponentSimple = ({
  copy,
  backgroundColor,
  textColor,
  headingColor,
  cta,
  textAlign,
}) => {
  return (
    <Box
      as="section"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      color={textColor || 'mineShaft'}
      bg={backgroundColor}
      lineHeight={3}
      py={6}
    >
      <Content
        color={textColor || 'mineShaft'}
        maxWidth="800px"
        px={[5, 5, 0]}
        headingColor={headingColor}
        dangerouslySetInnerHTML={{ __html: copy }}
        textAlign={textAlign}
      />
      {copy && cta && <Spacer my={3} />}
      {cta && cta[0].linkUrl && (
        <CtaLink variant={cta[0].variant || 'yellow'} url={cta[0].linkUrl}>
          {cta[0].linkLabel}
        </CtaLink>
      )}
    </Box>
  )
}

export default ComponentSimple
