import React from 'react'
import styled from 'styled-components'

import FluidImage from '../../components/FluidImage'
import { Box, Spacer, Text } from '../../components/Shared'
import { CtaLink } from '../../components/LinkCta'

import frame from '../../images/frameGolden.svg'

const Avatar = styled(FluidImage)`
  border-radius: 50%;
  height: auto;
  width: 14rem;
`

const Content = styled(Box)`
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    border: 3px solid ${({ theme }) => theme.colors.saffron};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    background-image: url(${frame});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 56rem;
  }
`

const StandoutTestimonial = ({ image, lead, linkText, testimonial, url }) => {
  return (
    <Content
      as="section"
      overflow="hidden"
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Spacer my={[2, 2, 5]} />
      <Box
        width="100vw"
        display="flex"
        flexDirection={['column', 'column', 'row']}
        justifyContent="center"
        alignItems="center"
        bg={['#FFF', '#FFF', 'gallery']}
        py={[0, 0, 3]}
        zIndex={-1}
      >
        <Avatar
          image={image}
          m={3}
          mr={[3, 3, 5]}
          bg={['#FFF', '#FFF', 'inherit']}
        />
        <Text
          bg="gallery"
          p={[5, 5, 0]}
          maxWidth="500px"
          textAlign={['center', 'center', 'left']}
          lineHeight={3}
          fontSize={4}
          fontWeight={600}
          color="toreaBay"
          dangerouslySetInnerHTML={{ __html: testimonial }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        py={5}
      >
        <Text
          textAlign="center"
          dangerouslySetInnerHTML={{ __html: lead }}
          px={[3, 3, 0]}
          maxWidth="750px"
        />
        <CtaLink url={url} my={4} variant="yellow">
          {linkText}
        </CtaLink>
      </Box>
    </Content>
  )
}

export default StandoutTestimonial
