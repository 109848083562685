import React from 'react'
import styled from 'styled-components'
import { Text } from '../../components/Shared'

const Content = styled(Text)`
  padding: 5rem;
  max-width: 1300px;
  margin: 0 auto;

  h1 {
    font-size: 2.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 1rem 0;
  }

  p {
    margin: 1rem 0;
  }

  li {
    margin-left: 3rem;
  }

  table,
  th,
  td {
    border: 1px solid black;
    padding: 1rem;
  }
  table {
    width: 100%;
  }
`

const LegalSection = ({ content }) => {
  return <Content as="article" dangerouslySetInnerHTML={{ __html: content }} />
}

export default LegalSection
