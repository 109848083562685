import React from 'react'
import { Box, Heading } from '../../components/Shared'
import { CtaLink } from '../../components/LinkCta'

const SimpleCta = (props) => {
  const { ctaText, heading, link } = props
  return (
    <Box
      as="section"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={[5, 5, 6]}
    >
      <Heading
        as="h2"
        fontSize={[6, 6, 6]}
        maxWidth="50%"
        textAlign="center"
        mb={5}
        color="burntSienna"
      >
        {heading}
      </Heading>
      {ctaText && (
        <CtaLink url={link} variant="orange">
          {ctaText}
        </CtaLink>
      )}
    </Box>
  )
}

export default SimpleCta
