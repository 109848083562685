/**
 * Various global options
 */

const Globals = {
  blogURI: 'blog',
  portfolioURI: 'my-portfolio',
}

module.exports = Globals
